<template>
  <div id="app" >
    <keep-alive
      
      exclude="Detail,SearchRes,shippingScheduleSearch,shippingSchedule,CompanyRegister,LoginChosenStatus"
    >
      <router-view ref="routeView" @clearCache="clearCache" />
    </keep-alive>
  </div>
</template>
<script>
import config from "./common/config";

export default {
  name: "APP",
  provide(){
    return {
      clearCache: this.clearCache
    }
  },
  methods: {
    async wxStart() {
      let that = this;
      const res = await this.service.get(config.url + "open/wechat/share", {
        params: {
          url: window.location.href,
        },
      });
      if (res.data?.code === 0) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "hideMenuItems",
          ], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          let share;
          if (
            ["/shipping_schedule", "/shipping_schedule_search"].includes(
              that.$route.path
            )
          ) {
            share = {
              title: "开截港查询", // 分享标题
              desc: "查询开港截港信息，就用箱动力", // 分享描述
              link: window.location.origin + "/shipping_schedule_search", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            };
          } else if (
            ["/bind", "/bind_success", "/bind_failed"].includes(
              that.$route.path
            )
          ) {
            share = {
              title: "箱动力", // 分享标题
              desc: "绑定公众号", // 分享描述
              link: window.location.origin + "/bind", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            };
          } else if (that.$route.path.startsWith("/box_images")) {
            share = {
              title: "箱动力", // 分享标题
              desc: "箱封照分享", // 分享描述
              link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            };
          } else {
            share = {
              title: "箱动力", // 分享标题
              desc: "箱动力货代版，下单查询都方便", // 分享描述
              link: window.location.origin, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            };
          }
          share = {
            imgUrl: window.location.origin + "/share.jpeg", // 分享图标
            success: function () {
              console.log("success");
            },
            fail: function () {
              console.log("fail");
            },
            ...share,
          };
          wx.updateAppMessageShareData(share);
          wx.updateTimelineShareData(share);
          wx.hideMenuItems({
            menuList: ["menuItem:copyUrl"], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
          });
        });
      }
    },
    //清除keep-alive缓存
    clearCache(){
      const routeView = this.$refs.routeView;
      if (routeView.$options.parent) {
        const {cache,keys} = routeView.$options.parent;
        keys.forEach(key=>{
          delete cache[key]
        })
        keys.splice(0,keys.length-1);
      }      
    }
  },
  created() {
    this.wxStart();
  },
};
</script>
<style scoped>
</style>
