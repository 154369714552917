<template>
      <div class="navigation_bar">
        <p class="back_icon iconfont" @click="backNav" v-if="back">&#xe601;</p>
        <p class="nav_title">{{this.title}}</p>
        <p class="search_icon iconfont" v-if="search" @click="changesearchbar">&#xe954;</p>
    </div>
</template>

<script>
export default {
  name: 'navgationBar',
  props:["back","title","search"],
  methods:{
      backNav(){
        this.$router.go(-1);
      },
      changesearchbar(){
        this.$emit("changeSearchbar",true)
      }
  }
}
</script>

<style scoped>
    .navigation_bar{
    width: 100%;
    height: 0.88rem;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(242, 242, 242, 1);
    box-shadow:0px 3px 6px rgba(0,0,0,0.16);
    z-index: 2;
}
.nav_title{
    text-align: center;
    line-height: 0.88rem;font-size:28px;
    font-weight:bold;
    color:rgba(48,49,51,1);
    font-size: 0.28rem;
}

.back_icon{
    position: absolute;
    left: 0.4rem;
    top: 0.26rem;
    font-size: 0.36rem;
}

.search_icon{
    position: absolute;
    right: 0.4rem;
    top: 0.26rem;
    font-size: 0.36rem;
}
</style>