<template>
  <div class="container">
      <div class="page-content">
          <img src="@/assets/images/warning.png" alt="">
          <div class="tip">{{$route.query.err||'绑定失败'}}</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Fail'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container{
    position: relative;
    width:100vw;
    height:100vh;
    .page-content{
        position: absolute;
        left:50%;
        top:40%;
        transform: translate(-50%,-50%);
        text-align: center;
        img{
            margin-bottom:0.4rem;
            width:1.2rem;
            height:1.2rem;
        }
        .tip{
            font-size:0.32rem;
            font-weight:bold;
            color:rgba(96,98,102,1);        
        }
    }
}
</style>
