/*
 * @Author: your name
 * @Date: 2020-09-03 19:00:08
 * @LastEditTime: 2021-07-06 11:14:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /chp_h5/src/common/request.js
 */
import axios from "axios"
import router from "@/router";
const service = axios.create({
    headers:{
        "content-type":"application/json;charset=UTF-8"
    }
})

service.interceptors.request.use(
    config=>{
        const token = localStorage.getItem("sessionToken")
        if (token){
            config.headers.Authorization = "Bearer " + token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    })

service.interceptors.response.use(
    res => {
        return res
    },
    error => {
        // let
        let status = error.response.status
        switch (status){
            case 401:
                alert("登录状态过期，请重新登录")
                localStorage.removeItem("sessionToken")
                router.replace({
                    name:"Login",
                    query:{
                        redirect_pro_url: window.location.href,
                    }
                })
                break
            case 403:case 404:case 418:
                alert("请求出错，请联系管理员")
                break
            case 500:
                alert("服务器维护中，请稍后再试")
                break
        }
        return Promise.reject(error.response.status)
    })

export default service