import { render, staticRenderFns } from "./tabBar.vue?vue&type=template&id=e107c31a&scoped=true"
import script from "./tabBar.vue?vue&type=script&lang=js"
export * from "./tabBar.vue?vue&type=script&lang=js"
import style0 from "./tabBar.vue?vue&type=style&index=0&id=e107c31a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e107c31a",
  null
  
)

export default component.exports