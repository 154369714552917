<template>
  <div>
    <navgationBar :back="true" :search="false" :title="'搜索结果'"></navgationBar>
    <mescroll-vue ref="mescroll" :down="mescroll.mescrollDown" :up="mescroll.mescrollUp" id="mescorll">
      <div class="content">
        <orderBox v-for="(item,index) in dataList" :box_status="boxStatus" :key="index" :data="item" @show_alert="show_copy_alert"  ></orderBox>
      </div>
    </mescroll-vue>
    <transition name="fade">
      <gralley :images="img_group" v-if="previewImg" :ChangePreview="changePreview" :slide_index="slide_index"></gralley>
    </transition>
    <transition name="fade">
      <div class="copy_alert" v-if="copy_alert_show">{{copy_alert_title}}</div>
    </transition>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
import navgationBar from '@/components/navgationBar.vue'
import config from "@/common/config"
import orderBox from "@/components/orderBox";
import Bus from "@/until/bus";
import no_order from '@/assets/no_order.png';
import moment from "moment";

export default {
    name:"SearchRes",
    components:{
      navgationBar,orderBox,MescrollVue
    },
    data(){
      return{
        copy_alert_title:"",
        copy_alert_show:false,
        slide_index:0,
        img_group:[],
        previewImg:false,
        boxStatus:0,
        mescroll: {
          mescrollUp: {
            callback: this.up,
            page: {
              num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
              size: 10 //每页数据条数,默认10
            },
            htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
            noMoreSize: 5,
            toTop: {
              src: "",
              offset: 1000 //列表滚动1000px才显示回到顶部按钮
            },
            empty: {
              //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
              warpId: "mescorll", //父布局的id (1.3.5版本支持传入dom元素)
              icon: no_order, //图标,默认null,支持网络图
              tip: "未搜索到订单" //提示
            }
          },
        },
        dataList: [],
      }
    },
    mounted(){
    },
    created() {
      var that = this
      Bus.$on("preview_imgs",(r,i) => {
        that.img_group = r;
        that.slide_index = i
        that.changePreview(true)
      })
      Bus.$on("hidegralley",()=>{
        // console.log(22222)
        that.previewImg = false
      })
    },
  beforeRouteEnter(to,from,next){
      // var token = localStorage.getItem('token')
      // if (!token && typeof(token) != "undefined" && token != 0) {
      //   next(vm=>{
      //     vm.$router.push({ path: '/login'})
      //   })
      // }else{
      next(vm => {
        // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
        vm.$refs.mescroll && vm.$refs.mescroll.beforeRouteEnter()
      })
      // }
    },

    beforeRouteLeave (to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteLeave不用写
      // 记录列表滚动的位置,隐藏顶部按钮和isBounce的配置
      this.$refs.mescroll && this.$refs.mescroll.beforeRouteLeave()
      next()
    },
    methods:{
      show_copy_alert(title){
        var that = this;
        this.copy_alert_title = title;
        this.copy_alert_show = true
        setTimeout(function (){
          that.copy_alert_show = false
        },500);
      },
      changePreview(bool){
        this.previewImg = bool
      },
      up(page, mescroll) {
        let num = page.num - 1
        let keywords = this.$route.query.keywords
        let searchType = this.$route.query.searchType
        var state = 0
        var search_data = {}
        if (searchType == 0 ){
          search_data = {
            orderState:state,
            number:keywords,
            containerTypes: [20, 40, 41, 46]
          }
        }else if(searchType == 1){
          search_data = {
            orderState:state,
            extractNo:keywords
          }
        }else  if(searchType == 2){
          search_data = {
            orderState:state,
            shipName:keywords
          }
        }else {
          search_data = {
            orderState:state,
            shipNo:keywords
          }
        }

        search_data.orPackUpAtIsNull = true
        search_data.containerTypes = [20, 40, 41, 46]
        search_data.pageNumber = num,
        search_data.pageOrder = [],
        search_data.pageSize = page.size
        search_data.packUpAtEnd = moment().add(1,'year').startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        search_data.packUpAtStart = moment().subtract(1,'year').endOf('day').format('YYYY-MM-DDTHH:mm:ss')

        // 联网请求
        
        this.service.post(config.url + "cus/order/page", search_data).then((response) => {
          console.log(response.data)
          // 请求的列表数据
          let arr = response.data.data.content
          // 如果是第一页需手动置空列表
          if (page.num === 1) this.dataList = []
          // 把请求到的数据添加到列表
          this.dataList = this.dataList.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          console.log(e)
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr()
        })
      },
    }
}
</script>

<style scoped>
.mescroll {
  position: fixed;
  top: 0.88rem;
  bottom: 0rem;
  height: auto;
  z-index: 0;
  overflow: auto;
}

.content{
  padding:0.2rem;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.copy_alert{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 50%;
  height: 44px;
  background-color: rgba(29,29,29,.9);
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 44px;
}
</style>