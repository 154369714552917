import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Detail from '../views/Detail.vue'
import SearchRes from '../views/SearchRes.vue'
import Login from '../views/Login.vue'
import Register from '../views/register/index.vue'
import Bind from '@/views/bind/Bind.vue'
import BindSuccess from '@/views/bind/Success.vue'
import BindFailed from '@/views/bind/Fail.vue'
import shippingSchedule from '@/views/shippingSchedule/index.vue'
import shippingScheduleSearch from '@/views/shippingSchedule/search.vue'
import boxImages from '@/views/boxImages/index.vue'
import RegisterChose from '@/views/register/chose.vue'
import TruckingRegister from '@/views/register/truckingRegister'
import CompanyRegister from '@/views/register/companyRegister.vue'
import Status from "@/views/Status"
Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title:'工作台'
    }
  },
  {
    path:'/detail',
    name:'Detail',
    component:Detail,
    meta:{
      title:'订单详情'
    }
  },
  {
    path:'/search',
    name:'SearchRes',
    component:SearchRes,
    meta:{
      title:'搜索结果'
    }
  },
  {
    path:'/login',
    name:'Login',
    component:Login,
    meta:{
      title: '登录'
    }
  },
  {
    path:'/login_chose_status',
    name:'LoginChosenStatus',
    component:Status,
    meta:{
      title: '选择登录身份',
      authorize: true
    }    
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    redirect: '/register/index',
    meta:{
      title: '注册',
      authorize: true
    },
    children:[{
      path: '/register/index',
      name: 'RegisterHome',
      component: RegisterChose,
      meta:{
        title: '注册',
        authorize: true
      }
    },{
      path: '/register/company',
      name: 'CompanyRegister',
      component: CompanyRegister,
      meta:{
        title: '注册',
        authorize: true
      }
    },{
      path: '/register/trucking',
      name: 'TruckingRegister',
      component: TruckingRegister,
      meta:{
        title: '注册',
        authorize: true
      }
    }]
  },
  {
    path: '/bind',
    name: 'Bind',
    component: Bind,
    meta:{
      title: '绑定',
      authorize: true
    }
  },
  {
    path: '/bind_success',
    name: 'BindSuccess',
    component: BindSuccess,
    meta:{
      title: '绑定成功',
      authorize: true
    }
  },
  {
    path: '/bind_failed',
    name: 'BindFailed',
    component: BindFailed,
    meta:{
      title: '绑定失败',
      authorize: true
    }
  },
  {
    path: '/shipping_schedule',
    name: 'shippingSchedule',
    component: shippingSchedule,
    meta:{
      title: '宁波港-船期查询',
      authorize: true
    }
  },
  {
    path: '/shipping_schedule_search',
    name: 'shippingScheduleSearch',
    component: shippingScheduleSearch,
    meta: {
      title: '宁波港-船期查询',
      authorize: true
    }
  },
  {
    path: '/box_images/:token',
    name: 'boxImages',
    component: boxImages,
    meta: {
      title: '箱封照',
      authorize: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
