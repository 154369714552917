<template>
  <div>
    
  </div>
</template>
<script>
export default {
  name: "shippingScheduleSearch",
  created() {
    window.location.href="/shipping_schedule"
  },
};
</script>