<template>
  <div class="status">
    <div class="status-list">
      <div 
        class="status-list-item"
        v-for="(item) in list"
        :key="item.id"
        @click="chose(item.id)"
      >
        <div class="status-list-item-content">
          <div class="status-list-item-content-company">{{item.companyName}}</div>
          <div class="status-list-item-content-status">{{item.roleName}}</div>
        </div>
        <div :class="[uid===item.id?'selected-operate-btn':'operate-btn']" />
      </div>
    </div>
    <div class="btn-group">
      <div :class="[uid?'login-btn':'disabled-login-btn']" @click="login">确认登录</div>
      <div class="back-btn" @click="back">返回上一步</div>
    </div>
  </div>
</template>
<script>
  import config from "../common/config";
  export default {
    name:"LoginChosenStatus",
    data(){
      return{
        list:[],
        multiToken:this.$route.params.multiToken,
        uid:null
      }
    },
    inject:['clearCache'],
    created(){
      if (this.multiToken) {
        this.getList();
      } else {
        this.$router.replace({ name: "Login" });
      }
    },
    methods:{
      async getList(){
        const res = await this.service
        .post(config.url + "open/auth/multi_profile_list", {
          token: this.multiToken,
          authSource: 2
        }) 
        if(res.data?.code === 0){
          this.list = res.data.data;
        }
      },
      chose(uid){
        console.log(uid);
        this.uid = uid;
      },
      async login(){
        const res = await this.service
        .post(config.url + "open/auth/set_profile", {
          multiToken: this.multiToken,
          uid:this.uid,
          authSource: 2
        }) 
        if(res.data?.code === 0){
            localStorage.setItem("sessionToken", res.data.data.sessionToken);
            this.clearCache();
            //有重定向的页面则跳转重定向页面 否则跳转工作台
            let redirect_pro_url = this.$route.query.redirect_pro_url;
            if (redirect_pro_url) {
              const redirectUrlParams = new URL(redirect_pro_url);
              if (redirectUrlParams.origin === urlParams.origin) {
                redirect_pro_url = redirect_pro_url.substr(
                  urlParams.origin.length
                );
                if (redirect_pro_url.match(/^\/.*#/)) {
                  redirect_pro_url = redirect_pro_url.substr(
                    redirect_pro_url.indexOf("#") + 1
                  );
                }
              }
            }
            redirect_pro_url ? window.location.href = redirect_pro_url : this.$router.push({ name: "Home" });
        }else{
          switch (res.data?.code) {
            case 1: 
              alert(res.data.message);
            case 900:
              alert("账号或密码错误");
            case 901:
              alert("身份审核中");
            case 902:
              alert("身份被停用");
            case 950:
              alert("账号被停用");
          }
        }
      },
      back(){
         this.$router.back()
      }
    }
  }
</script>
<style lang="scss" scoped>
  .status{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.3rem;
    box-sizing: border-box;
    background:#F1F3F8;
  }
  .status-list{
    min-height: 0;
    flex-grow: 1;
    margin-bottom: 0.3rem;
    overflow-y: auto;
  }
  .btn-group{
    flex-shrink: 0;
  }
  .login-btn{
    height: 0.80rem;
    background: #006EEF;
    color: #fff;
    border-radius: 0.08rem;
    text-align:center;
    line-height: 0.8rem;
    font-size:0.32rem;
    margin-bottom:0.3rem;

  }
  .disabled-login-btn{
    height: 0.80rem;
    color: #fff;
    border-radius: 0.08rem;
    text-align:center;
    line-height: 0.8rem;
    font-size:0.32rem;
    margin-bottom:0.3rem;
      background:#d9d9d9;
  }
  .back-btn{
    text-align: center;
    line-height: 0.36rem;
    color: #9FA6BC;
  }
  .status-list-item{
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    padding: 0.2rem;
    box-shadow: 0rem 0.2rem 0.3rem 0px rgba(0,0,0,0.04);
    border-radius: 0.1rem;
    background: #fff;
  }
  .status-list-item-content{
    flex-grow: 1;
  }
  .status-list-item-content-company{
    font-size: 0.28rem;
    margin-bottom: 0.2rem;
    line-height: 0.39rem;
  }
  .status-list-item-content-status{
    font-size: 0.24rem;
    color: #0066FF;
  }
  .operate-btn{
    flex-shrink: 0;
    position: relative;
    width: 0.36rem;
    height: 0.36rem;
    border-radius:50%;
    border: 0.02rem solid #D9D9D9;
    background: #fff;
  }
  .selected-operate-btn{
    flex-shrink: 0;
    position: relative;
    width: 0.36rem;
    height: 0.36rem;
    border-radius:50%;
    border: 0.02rem solid #D9D9D9;
    background: #006EEF;
    &::after{
      position: absolute;
      width: 0.20rem;
      height: 0.20rem;
      left: 0.08rem;
      top: 0.08rem;
      left: 0.08rem;
      right: 0.08rem;
      content:"";
      background: #fff;
      border-radius:50%;
    }
  }
</style>