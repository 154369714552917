<template>
  <div :class="input_focus && isAndroid ? 'container' : 'container showBg'">
    <div class="tip" v-if="$route.query.isbind">
      请绑定您已注册的箱动力平台账号
    </div>
    <div class="login_logo">
      <img :src="require('@/assets/logo2.0@2x.png')" alt="" />
    </div>
    <div class="login_title">
      {{ $route.query.isbind ? "账号绑定" : "账号登录" }}
    </div>
    <div class="login_box">
      <div class="login_row">
        <i class="input_label">账号</i>
        <input
          class="input_ele"
          placeholder="请输入用户名"
          v-model="username"
          @focus="input_focus = true"
          @blur="input_focus = false"
        />
      </div>
      <div class="login_row">
        <i class="input_label">密码</i>
        <input
          class="input_ele"
          placeholder="请输入密码"
          v-model="password"
          :type="[input_type ? 'password' : 'text']"
          @focus="input_focus = true"
          @blur="input_focus = false"
        />
        <div class="changeType iconfont" @click="changeType">
          {{ input_type ? "&#xe622;" : "&#xe6c4;" }}
        </div>
      </div>
    </div>
    <div class="login_button" @click="handleSubmit">
      {{ $route.query.isbind ? "绑定" : "登录" }}
    </div>
    <!-- <div class="register_button_outer" v-if="$route.query.from !== 'bind'">
      <span 
        class="register_button"
        @click="goRegister"
      >去注册</span>
    </div> -->
  </div>
</template>

<script>
import config from "../common/config";
import JSEncrypt from "../common/jsencrypt.min.js";
import sha256 from "crypto-js/sha256";
import { parse } from "querystring";
import { isAndroid } from '@/until/fn.js'
const getPageQuery = () => parse(window.location.href.split("?")[1]);
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      input_type: false,
      input_focus: false,
      isAndroid: isAndroid()
    };
  },
  methods: {
    changeType() {
      this.input_type = !this.input_type;
    },
    handleSubmit() {
      if (this.username == "" || this.password == "") {
        alert("您的账号或密码未填写");
        return false;
      }
      if (this.$route.query.isbind) {
        this.bindAccount();
      } else {
        this.login();
      }
    },
    //获取加密密码
    async getEncrypted() {
      const res = await this.service.get(config.url + "open/auth/public_key");
      if (res.data?.code === 0) {
        let public_key = res.data.data.publicKey;
        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(public_key);
        let encrypted = encrypt.encrypt(
          sha256(sha256(this.password + "synb2333").toString()).toString()
        );
        res.data.data.encrypted = encrypted;
      }
      return res;
    },
    async bindAccount() {
      const res = await this.getEncrypted();
      if (res.data?.code === 0) {
        let date = new Date();
        this.service
          .post(`${config.url}open/auth/bind_wx_mp`, {
            mpCode: this.$route.query.code,
            datetime: date,
            key: res.data.data.privateKey,
            username: this.username,
            password: res.data.data.encrypted,
          })
          .then((response) => {
            if (response.data.code === 0 && response.data.data) {
              this.login();
              this.$router.push("/bind_success");
            } else {
              this.$router.push({
                path: "/bind_failed",
                query: { err: res.data.message },
              });
            }
          })
          .catch(() => {
            this.$router.push({ path: "/bind_failed" });
          });
      }
    },
    async login() {
      const res = await this.getEncrypted();
      if (res.data?.code === 0) {
        this.loginRequest({
          privateKey: res.data.data.privateKey,
          encrypted: res.data.data.encrypted,
        });
      }
    },
    loginRequest({ privateKey, encrypted }) {
      let date = new Date();
      this.service
        .post(config.url + "open/auth", {
          datetime: date,
          key: privateKey,
          username: this.username,
          password: encrypted,
          authSource: 2
        })
        .then((res) => {
          let code = res.data.code;
          switch (code) {
            case 1: 
              alert(res.data.message);
              return;
            case 900:
              alert("账号或密码错误");
              return;
            case 901:
              alert("身份审核中");
              return;
            case 902:
              alert("身份被停用");
              return;
            case 950:
              alert("账号被停用");
              return;
          }
          const urlParams = new URL(window.location.href);
          const params = getPageQuery();
          let { redirect_pro_url } = params;
          if(!res.data.data.multiProfile){
            localStorage.setItem("sessionToken", res.data.data.sessionToken);
            this.$emit('clearCache');
            //有重定向的页面则跳转重定向页面 否则跳转工作台
            if (redirect_pro_url) {
              const redirectUrlParams = new URL(redirect_pro_url);
              if (redirectUrlParams.origin === urlParams.origin) {
                redirect_pro_url = redirect_pro_url.substr(
                  urlParams.origin.length
                );
                if (redirect_pro_url.match(/^\/.*#/)) {
                  redirect_pro_url = redirect_pro_url.substr(
                    redirect_pro_url.indexOf("#") + 1
                  );
                }
              }
            }
            redirect_pro_url ? window.location.href = redirect_pro_url : this.$router.push({ name: "Home" });
          }else{
            this.$router.push({ name: "LoginChosenStatus",params: {
              multiToken:res.data.data.multiToken
            },query:{
              redirect_pro_url
            }})
          }

        });
    },
    goRegister() {
      this.$router.push({
        name: "Register",
        params: {
          step: "index",
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    var token = localStorage.getItem("sessionToken");
    //从绑定页过来不跳转
    if (to.query.isbind || !token) {
      next();
    } else {
      next((vm) => {
        vm.$router.push({ name: "Home" });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.login_logo {
  padding-top: 1.8rem;
  margin: 0 auto;
  text-align: center;
  img {
    width: 3.26rem;
  }
}
.container {
  width: 100%;
  height: 100%;
  font-size: 0.32rem;
  &.showBg{
    background-image: url("~@/assets/login_bg.png");
    background-size: 100% auto;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }
}
.tip {
  margin-bottom: 1.6rem;
  padding-left: 0.2rem;
  height: 0.8rem;
  background: rgba(245, 26, 70, 0.1);
  font-size: 0.24rem;
  font-weight: 400;
  line-height: 0.8rem;
  color: rgba(245, 26, 70, 1);
}
.login_title {
  margin-top: 1.48rem;
  font-size: 0.4rem;
  font-weight: 500;
  color: #233953;
  margin-left: 0.4rem;
}

.login_box {
  margin-top: 0.88rem;
}

.login_row {
  position: relative;
  width: 6.7rem;
  height: 0.66rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(205, 205, 205, 1);
  margin: 0 auto 0.82rem auto;
}

.input_label {
  margin-right: 0.32rem;
  color: #1c2e45;
}

.input_ele {
  width: 5.4rem;
}
.input_ele[type="password"] {
  padding-right: 0.76rem;
  box-sizing: border-box;
}
.changeType {
  position: absolute;
  font-size: 0.32rem;
  top: 0.19rem;
  right: 0.2rem;
}

.login_button {
  width: 6.7rem;
  height: 0.9rem;
  background-color: rgba(20, 83, 254, 1);
  text-align: center;
  color: white;
  line-height: 0.9rem;
  margin: 0 auto;
  border-radius: 4px;
}
.register_button_outer {
  padding: 0 0.4rem;
  margin-top: 0.24rem;
  text-align: right;
  color: #0066ff;
}
</style>