<template>
  <div class="container">
    <div class="page-content">
      <div class="img img-logo">
        <img src="@/assets/images/logo3.png" alt="" />
      </div>
      <div class="img img-text">
        <img src="@/assets/images/logo4.png" alt="" />
      </div>
      <div class="tip">绑定微信号，实时获得订单信息提醒</div>
      <div class="btn" @click="getCode">立即绑定</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  created() {
    document.title = "绑定账号";
  },
  methods: {
    getCode() {
      let local, appid;
      if (process.env.NODE_ENV === "production") {
        //线上环境
        appid = "wx1be79095eff43b2a";
      } else {
        //本地环境
        appid = "wx3f84d8725862d996";
      }
      local = window.location.origin + "/login?isbind=1";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        local
      )}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  .page-content {
    width: 5.6rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%);
    text-align: center;
    .img-logo {
      margin: 0 auto 0.6rem;
      width: 1.6rem;
      height: 1.6rem;
    }
    .img-text {
      margin: 0 auto 0.17rem;
      width: 2.05rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip {
    margin-bottom: 2rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: #9fa6bc;
  }
  .btn {
    height: 0.9rem;
    line-height: 0.9rem;
    color: #fff;
    background: rgba(85, 184, 55, 1);
    font-size: 0.36rem;
    border-radius: 0.12rem;
  }
}
</style>
