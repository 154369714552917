<template>
    <div class="container">
        <transition name="fade">
            <div class="bg" v-if="search_bar_status">
            </div>
        </transition>
        <transition name="translate">
            <div class="search_bar_container" v-if="search_bar_status">
                <div class="search_bar">
                    <select v-model="searchType" class="search_select">
                      <option value="0">订单号</option>
                      <option value="1">提单号</option>
                      <option value="2">船名</option>
                      <option value="3">航次</option>
                    </select>
                    <input class="search_input" type="text" placeholder="请输入搜索关键字" v-model="keywords">
                    <div class="search_button" @click="search">搜索</div>
                </div>
                <div class="search_bg" @click.stop="hideSearchBar"></div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name:"searchBar",
    props:["search_bar_status","tab_status"],
    data(){
        return{
            keywords:"",
            searchType:"0"
        }
    },
    methods:{
        hideSearchBar(){
            this.$emit("changeSearchbar",false)
        },
        search(){
            if (this.keywords ==""){
                alert("您未输入搜索内容")
            }else{
                this.$router.push({ path: 'search', query: { keywords: this.keywords ,searchType:this.searchType,tab_status:this.tab_status}})
            }
           
        }
    }
}
</script>

<style scoped>
  .container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .search_bar_container{
    position: fixed;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    z-index: 3;
  }

  .search_input{
    padding: 0 10px;
    box-sizing: border-box;
  }

  .search_select{
    color: rgba(50,50,50,1);
  }

  .search_bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top:0.6rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 7.1rem;
    background-color:white;
    box-shadow:0px 6px 12px rgba(0,0,0,0.3);
    padding: 0.2rem;
    border-radius: 0.08rem;
    box-sizing: border-box;
    z-index: 2;
  }
  .search_bar input{
    width: 6rem;
    line-height: 0.58rem;
    font-size: 0.32rem;
  }

  .search_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .bg{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0,0,0,0.4);
  }

  .search_button{
    width: 1.5rem;
    height: 0.66rem;
    background-color: rgba(20, 83, 254, 1);
    color:white;
    text-align: center;
    line-height: 0.66rem;
    border-radius: 0.08rem;
    font-size: 14px;
    letter-spacing: 2px;
  }

  .translate-enter-active, .translate-leave-active {
    transform: translateY(0%);
    transition: transform .3s;
  }
  .translate-enter, .translate-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(-100%);
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>