<template>
  <div>
    <navgationBar :back="back" :search="search" :title="title"></navgationBar>
    <div class="content">
      <div class="panel">
        <div class="order_row">
          <p class="order_title">订单编号：</p>
          <p class="order_info">{{ detail_data.number }}</p>
        </div>
        <p class="info-title">集装箱信息</p>
        <div class="info_row">
          <p class="title">船名/航次：</p>
          <p class="txt">
            <span>{{ detail_data.ediBookingVO.shipName + " / " }}</span
            ><span>{{ detail_data.ediBookingVO.shipNo }}</span>
          </p>
        </div>
        <div class="info_row">
          <p class="title">提单号：</p>
          <p class="txt">{{ detail_data.ediBookingVO.extractNo }}</p>
        </div>
        <div class="info_row">
          <p class="title">派单车队：</p>
          <p class="txt">{{ detail_data.carName }}</p>
        </div>
        <div class="info_row">
          <p class="title">箱型：</p>
          <p class="txt">1 X {{ containerTypeCN }}</p>
        </div>
        <div class="info_row">
        <p class="title">特种箱型：</p>
        <p class="info">{{ detail_data.useSpecialCtn? specialCtnTypeCN:""}}</p>
      </div>
        <div class="info_row">
          <p class="title">箱号：</p>
          <p class="txt">{{ detail_data.containerNo }}</p>
        </div>
        <div class="info_row">
          <p class="title">封号：</p>
          <p class="txt">{{ detail_data.ediBookingVO.sealNo }}</p>
        </div>
        <div class="info_row">
          <p class="title">柜重：</p>
          <p class="txt">{{ detail_data.ediBookingVO.containerWeight }}KGS</p>
        </div>
        <div class="info_row">
          <p class="title">序列号：</p>
          <p class="txt">{{ detail_data.ediBookingVO.serialNo }}</p>
        </div>
        <div class="info_row">
          <p class="title">箱封照：</p>
          <div class="imgbox">
            <img
              v-for="(item, index) in img_group"
              :preview="detail_data.id"
              class="imginfo"
              :src="item"
              :key="index"
            />
          </div>
        </div>
        <p class="info-title">装货信息</p>
        <div class="info_row">
          <p class="title">装箱时间：</p>
          <p class="txt">{{ detail_data.packUpAt | fPackUpAt }}</p>
        </div>

        <div class="info_row">
          <p class="title">装箱地址：</p>
          <div class="info">
            <p
              v-for="(item, index) in detail_data.packUpLocationVO"
              :key="index"
            >
              <span>{{ item.areaFormat }} </span><span>{{ item.detail }} </span
              ><span>{{ item.contactName }} </span
              ><span>{{ item.contactPhone }} </span>
            </p>
          </div>
        </div>
        <div class="info_row">
          <p class="title">放箱位置：</p>
          <p class="txt" v-if="detail_data.packUpPosition == 1">无要求</p>
          <p class="txt" v-if="detail_data.packUpPosition == 2">放前</p>
          <p class="txt" v-if="detail_data.packUpPosition == 3">放后</p>
        </div>
        <div class="info_row">
          <p class="title">装箱次序：</p>
          <p class="txt" v-if="detail_data.packUpSeq == 1">无要求</p>
          <p class="txt" v-if="detail_data.packUpSeq == 2">先装</p>
          <p class="txt" v-if="detail_data.packUpSeq == 3">后装</p>
        </div>
        <div class="info_row">
          <p class="title">提箱堆场：</p>
          <p class="txt">{{ detail_data.ediBookingVO.extractGround }}</p>
        </div>
        <div class="info_row">
          <p class="title">还箱堆场：</p>
          <p class="txt">{{ detail_data.ediBookingVO.returnGround }}</p>
        </div>
        <div class="info_row">
          <p class="title">装箱要求：</p>
          <p class="txt">{{ detail_data.packUpNote }}</p>
        </div>
        <p class="info-title">司机信息</p>
        <div class="info_row">
          <p class="title">司机姓名：</p>
          <p class="txt">{{ detail_data.driverVO.tempDriverName }}</p>
        </div>
        <div class="info_row">
          <p class="title">手机号码：</p>
          <p class="txt">{{ detail_data.driverVO.tempDriverPhone }}</p>
        </div>
        <div class="info_row">
          <p class="title">车牌号：</p>
          <p class="txt">{{ detail_data.driverVO.tempCarLicence }}</p>
        </div>
        <div class="info_row">
          <p class="title">白卡号：</p>
          <p class="txt">{{ detail_data.driverVO.whiteCardNumber }}</p>
        </div>
      </div>
    </div>
    <div
      class="botton_button"
      @click="copy"
    >
      报箱封号
    </div>
    <transition name="fade">
      <gralley
        :images="img_group"
        v-if="previewImg"
        :ChangePreview="changePreview"
        :slide_index="slide_index"
      ></gralley>
    </transition>
    <transition name="fade">
      <div class="copy_alert" v-if="copy_alert_show">
        {{ copy_alert_title }}
      </div>
    </transition>
  </div>
</template>

<script>
import "../mock";
import navgationBar from "../components/navgationBar";
// import gralley from '@/components/gralley.vue'
import Bus from "../until/bus";
import config from "@/common/config";
import Clipboard from "clipboard";
import moment from "moment";
export default {
  name: "Detail",
  data() {
    return {
      containerTypeCN: "",
      copy_alert_title: "",
      copy_alert_show: false,
      base_copy_data:"",
      copy_data: "",
      back: true,
      search: false,
      title: "订单详情",
      detail_data: {
        id: "",
        containerType: 1,
        state: 1,
        cancelState: 0,
        carTeamId: 0,
        carName: "",
        masterUser: {
          id: 0,
          name: "",
          qq: "",
          wechat: "",
          telephone: "",
          mobilePhone: "",
        },
        createdAt: "",
        number: "",
        containerNo: "",
        sendOfState: 0,
        specialCtnTypeCN:"",
        useSpecialCtn:false,
        packUpAt: "",
        packUpNote: "",
        goodsGrossWeight: "",
        packUpPosition: 0,
        packUpSeq: 0,
        containerAttVOList: [],
        packUpLocationVO: [],
        imExType: 0,
        cutoffAt: "",
        ediBookingVO: {
          serialNo: "",
          extractNo: "",
          shipName: "",
          shipNo: "",
          extractGround: "",
          returnGround: "",
          portOfDestination: "",
          portOfTransfer: "",
          actAsCode: "",
          closePortAt: "",
          openPortAt: "",
          sealNo: "",
          containerWeight: "",
          sealAttList: [],
        },
        driverVO: {
          tempDriverName: "",
          tempDriverPhone: "",
          tempCarLicence: "",
        },
        recState: "",
        recFeeDetail: [],
        recRemark: "",
      },
      img_group: [],
      previewImg: false,
      slide_index: 0,
    };
  },
  filters: {
    fPackUpAt(value) {
      return value ? moment(value).format("MM-DD/HH点") : "";
    },
  },
  components: {
    navgationBar,
  },
  created() {
    var that = this;
    Bus.$on("preview_imgs", (r, i) => {
      that.img_group = r;
      this.slide_index = i;
      that.changePreview(true);
    });
    Bus.$on("hidegralley", () => {
      that.previewImg = false;
    });
  },
  mounted() {
    var that = this;
    const id = this.$route.query.id;
    this.service.get(config.url + "cus/order/" + id + "/").then(async (res) => {
      let data = res.data.data;
      this.detail_data = data;
      if (!this.detail_data.driverVO) {
        this.detail_data.driverVO = {
          tempDriverName: "",
          tempDriverPhone: "",
          tempCarLicence: "",
          whiteCardNumber: "",
        };
      }
      let packUpAt = data.packUpAt ? moment(data.packUpAt).format("YYYY.MM.DD HH点") : "";
      let shipName =
        data.ediBookingVO.shipName == null ? "" : data.ediBookingVO.shipName;
      let shipNo =
        data.ediBookingVO.shipNo == null ? "" : data.ediBookingVO.shipNo;
      let extractNo =
        data.ediBookingVO.extractNo == null ? "" : data.ediBookingVO.extractNo;
      let number = data.containerNo == null ? "" : data.containerNo;
      let closeNo =
        data.ediBookingVO.sealNo == null ? "" : data.ediBookingVO.sealNo;
      let weight =
        data.ediBookingVO.containerWeight == null
          ? ""
          : data.ediBookingVO.containerWeight + "KGS";
      let serialNo =
        data.ediBookingVO.serialNo == null ? "" : data.ediBookingVO.serialNo;
      let packUpLocation =
        data.packUpLocationVO == null ? [] : data.packUpLocationVO;
      let extractGround =
        data.ediBookingVO.extractGround == null
          ? ""
          : data.ediBookingVO.extractGround;
      let returnGround =
        data.ediBookingVO.returnGround == null
          ? ""
          : data.ediBookingVO.returnGround;
      let packAt = data.packUpAt
        ? moment(data.packUpAt).format("MM-DD/HH点")
        : "";
      this.packAt = packAt;

      let sealAtt = data.ediBookingVO.sealAttList;
      for (let i = 0; i < sealAtt.length; i++) {
        that.img_group.push(sealAtt[i].url);
      }

      switch (data.containerType) {
        case 20:
          this.containerTypeCN = "20GP";
          break;
        case 40:
          this.containerTypeCN = "40GP";
          break;
        case 41:
          this.containerTypeCN = "40HQ";
          break;
        case 46:
          this.containerTypeCN = "45HQ";
          break;
      }
      switch (data.specialCtnType) {
        case 21:
          this.specialCtnTypeCN ="框架箱 FR"
          break;
        case 22:
          this.specialCtnTypeCN ="冷藏箱 RF"
          break;
        case 23:
          this.specialCtnTypeCN ="冷藏高箱 RH"
          break;
        case 24:
          this.specialCtnTypeCN ="油罐箱 TK"
          break;
        case 25:
          this.specialCtnTypeCN ="开顶箱 OT"
          break;
        case 26:
          this.specialCtnTypeCN ="开顶箱 OQ"
          break;
        case 51:
          this.specialCtnTypeCN ="框架箱 FR"
          break;
        case 52:
          this.specialCtnTypeCN ="冷藏箱 RF"
          break;
        case 53:
          this.specialCtnTypeCN ="油罐箱 TK"
          break;
        case 54:
          this.specialCtnTypeCN ="开顶箱 OT"
          break;
        case 55:
          this.specialCtnTypeCN ="开顶箱 OQ"
          break;
        case 61:
          this.specialCtnTypeCN ="冷藏高箱 RH"
          break;
        case 71:
          this.specialCtnTypeCN ="冷藏箱 RF"
          break;
      }
      var tmp_copy_1 =
        "装箱时间：" +
        packUpAt + 
        "\n" +
        "船名/航次：" +
        shipName +
        " / " +
        shipNo +
        "\n" +
        "提单号：" +
        extractNo +
        "\n" +
        "箱号：" +
        number +
        "\n" +
        "封号：" +
        closeNo +
        "\n" +
        "柜重：" +
        weight +
        "\n" +
        "序列号：" +
        serialNo +
        "\n" +
        "箱型：" +
        "1 X " +
        this.containerTypeCN +
        "\n" +"特种箱型："+  (data.useSpecialCtn ?  this.specialCtnTypeCN + "\n" : ""+ "\n");;

      var tmp_copy_2 = "装箱地址：";
      for (let j = 0; j < packUpLocation.length; j++) {
        let location = packUpLocation[j];
        tmp_copy_2 +=
          (j === 0 ? "" : "                  ") +
          `${location.areaFormat} ${location.detail} ${location.contactName} ${location.contactPhone}\n`;
      }

      var tmp_copy_3 =
        "提箱堆场：" +
        extractGround +
        "\n" +
        "还箱堆场：" +
        returnGround +
        "\n";

      var tmp_copy_4 = "";

      let name = data?.driverVO?.tempDriverName
        ? data?.driverVO?.tempDriverName
        : "";
      let phone = data?.driverVO?.tempDriverPhone
        ? data?.driverVO?.tempDriverPhone
        : "";
      let carLicence = data?.driverVO?.tempCarLicence
        ? data?.driverVO?.tempCarLicence
        : "";
      let whiteCardNumber = data?.driverVO?.whiteCardNumber
        ? data?.driverVO?.whiteCardNumber
        : "";
      tmp_copy_4 =
        "司机：" +
        name +
        "\n" +
        "手机号码：" +
        phone +
        "\n" +
        "车牌号：" +
        carLicence +
        "\n" +
        "白卡号：" +
        whiteCardNumber +
        "\n";
      this.base_copy_data = tmp_copy_1 + tmp_copy_2 + tmp_copy_3 + tmp_copy_4;
      const response = await this.service.get(
        config.url + `general/order/${this.detail_data.id}/letter_box_title`
      );
      const boxImgUrl = response.data.data.boxImgUrl;
      this.copy_data = this.base_copy_data;
      if (this.detail_data.ediBookingVO.sealAttList.length > 0 && boxImgUrl) {
        this.copy_data += "箱封照：" + boxImgUrl + "\n";
      }
      this.$previewRefresh();
    });
  },
  methods: {
    preview(index) {
      Bus.$emit("preview_imgs", this.img_group, index);
    },
    changePreview(bool) {
      this.previewImg = bool;
    },
    copy() {
      let el = document.createElement("textarea");
      el.value = this.copy_data;
      el.style.opacity = "0";
      document.querySelector("body").appendChild(el);
      el.select();
      // 执行复制
      document.execCommand("Copy");
      document.querySelector("body").removeChild(el);
      this.copy_alert_title = "复制成功";
      this.copy_alert_show = true;
      setTimeout(()=> {
        this.copy_alert_show = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
.content {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0.88rem;
  box-sizing: border-box;
  padding-top: 0.2rem;
  overflow: auto;
}

.panel {
  padding-bottom: 2rem;
}

.order_row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0.2rem 0.2rem 0.3rem 0.4rem;
}

.order_title {
  font-size: 0.28rem;
  font-weight: 400;
  line-height: 0.24rem;
  color: rgba(48, 49, 51, 1);
}

.order_info {
  font-size: 0.28rem;
  font-weight: 500;
  line-height: 0.24rem;
  color: rgba(48, 49, 51, 1);
}

.info-title {
  width: 100%;
  height: 0.8rem;
  background-color: rgba(234, 239, 251, 1);
  font-size: 0.28rem;
  font-weight: 500;
  color: rgba(48, 49, 51, 1);
  line-height: 0.8rem;
  box-sizing: border-box;
  padding-left: 0.4rem;
}

.info_row {
  margin-left: 0.2rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.32rem;
  margin-top: 0.2rem;
}

.left_marg {
  margin-left: 0.4rem;
}

.title {
  width: 1.6rem;
  color: rgba(102, 102, 102, 1);
  font-size: 0.28rem;
  text-align: right;
  flex-shrink:0;
}
.txt{
  word-break:break-all;
}
.info {
  font-size: 0.28rem;
  font-weight: 500;
  color: rgba(50, 50, 50, 1);
  width: 5.25rem;
}

.info p {
  font-size: 0.28rem;
  font-weight: 400;
  color: rgba(50, 50, 50, 1);
  margin-bottom: 0.2rem;
  line-height: 0.4rem;
}

.info p:last-child {
  margin-bottom: 0rem;
}

.imgbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.imginfo {
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: 0.2rem;
  margin-right: 0.1rem;
}

.botton_button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.88rem;
  text-align: center;
  background-color: rgba(20, 83, 254, 1);
  line-height: 0.88rem;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.copy_alert {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 50%;
  height: 44px;
  background-color: rgba(29, 29, 29, 0.9);
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 44px;
}
</style>