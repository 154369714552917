/*
 * @Author: your name
 * @Date: 2020-11-16 10:56:52
 * @LastEditTime: 2024-02-27 14:13:52
 * @LastEditors: 2239486223@qq.com kh0_8f3zbzjju@dingtalk.com
 * @Description: In User Settings Edit
 * @FilePath: /chp_h5/src/common/config.js
 */


//let url = "https://test-api.nbchp.com/api/"
// let url = "/api/"
let url
switch(process.env.NODE_ENV){
    case 'local':{
        url = "http://192.168.1.151:8686/api/";
        break;
    }
    case 'pre':{
        url = "https://snap-api.chp.ink/api/";
        break;
    }
    case "pro": {
        url = "https://a-api.nbchp.com/api/";
        break;
    }
    default:{
        url = api_domain + "/api/"
    }
}
//let file_url = "https://test-api.nbchp.com/"
let file_url = "/"
let action_downloading_type = {
    d:"d",
    o:"o"
}

module.exports = {
    url,file_url,action_downloading_type
}
