<template>
    <div>
        <div class="content">
          <img class="bg"  :src="bg" alt="背景"/>
          <div class="first_box">
            <img class="logo" :src="logo" alt="头像"/>
            <p class="true_name">{{ true_name }}</p>
          </div>
            <div class="infobox">
                <div class="statistics_box">
                    <div class="statistics">
                        <p class="s_title">待派单</p>
                        <p class="s_value">{{ wait_order }}</p>
                    </div>
                    <div class="statistics">
                        <p class="s_title">进行中</p>
                        <p class="s_value">{{ ing_order }}</p>
                    </div>
                    <div class="statistics">
                        <p class="s_title">已完成</p>
                        <p class="s_value">{{ finish_order }}</p>
                    </div>
                    <div class="statistics">
                        <p class="s_title">累计订单</p>
                        <p class="s_value">{{ total_order }}</p>
                    </div>
                </div>
            </div> 
            <div class="other_info">
                <div class="info_row">
                        <img class="small_logo" :src="company" alt="企业名称" />
                        <p class="txt">{{ company_name }}</p>
                </div>
                <div class="info_row">
                        <img class="small_logo"  :src="logout" alt="退出登录"/>
                        <p class="txt" @click="close_dialog(true)">退出登录</p>
                </div>
            </div>            
        </div>
        <transition name="fade">
            <div class="login_out_dialog_container" @click="close_dialog(false)" v-if="login_dialog">
                <div class="login_out_dialog">
                    <p class="login_out_tip">您确定要退出登录吗？</p>
                    <div class="btn_box">
                        <div class="btn" @click.stop="login_out">确认</div>
                        <div class="btn cancel" @click="close_dialog(false)">取消</div>
                    </div>               
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import config from "@/common/config"

export default {
    name:"Account",
    props:['companyInfo', 'tabBarStatus'],
    data(){
        return{
            bg:require("../assets/bg.png"),
            logo:require("../assets/logo.png"),
            company:require("../assets/company.png"),
            logout:require("../assets/logout.png"),
            login_dialog:false,
            company_name:this.companyInfo.companyName,
            true_name:this.companyInfo.name,
            wait_order:0,
            ing_order:0,
            finish_order:0,
            total_order:0
        }
    },
    watch:{
        tabBarStatus(value){
            if(value == 6){
                this.getCount()
            }
        },
    },
    methods:{
        close_dialog(bool){
            this.login_dialog = bool
        },
        login_out(){
            localStorage.removeItem("sessionToken")
            this.$router.push({name:"Login"})
            this.login_dialog = false
        },
        getCount(){
            var that = this
            this.service.get(config.url + "cus/order/order_count").then(res=>{
                that.wait_order = res.data.data.toSendASingle
                that.ing_order = res.data.data.processing
                that.finish_order = res.data.data.completed
                that.total_order = res.data.data.accumulative
            })
        }
    }
}
</script>

<style scoped>
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        z-index: -1;
    }

    .first_box{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 3.03rem;
    }

    .content{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
    }

    .logo{
        display: block;
        /* position: absolute;
        top: 3.91rem;
        left: 0.64rem; */
        width: 1.52rem;
        height: 1.52rem;
        z-index: 1;
        border-radius: 50%;
        border: 0.04rem solid white;
        margin-left: 0.6rem;
        
    }

    .infobox{
        position: absolute;
        width: 6.7rem;
        height: 2rem;
        margin: 4.62rem auto 0 auto;
        top:5.23rem;
        left:0;
        right: 0;
        margin: auto;
        background-color: white;
        box-shadow:0rem 0.03rem 0.06rem rgba(0,0,0,0.16);
        border-radius:0.24rem;
        overflow: hidden;
    }

    .true_name{
        font-size:0.36rem;
        font-weight:500;
        color:white;
        line-height: 1.6rem;
        margin-left: 0.4rem;
    }

    .statistics_box{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 0.5rem;
    }

    .statistics{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.3%;
    }

    .s_title{
        font-size:0.26rem;
        font-weight:500;
        color:rgba(102,102,102,1);
        margin-bottom: 0.12rem;
    }

    .s_value{
        font-size:0.68rem;
        font-weight:500;
        color:rgba(50,50,50,1);
    }

    .info_row{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 6.7rem;
        height: 0.8rem;
        margin: 0.4rem auto;
        border-bottom:1px solid rgba(234,239,251,1);
    }

    .small_logo{
        width: 0.6rem;
        margin-right: 0.25rem;
    }

    .icon{
        margin: 0 0.2rem;
        font-size: 0.36rem;
        color:rgba(102,102,102,1);
    }

    .txt{
        font-size:0.29rem;
        font-weight:500;
        color:rgba(48,49,51,1);
    }

    .login_out_dialog_container{
        position: fixed;
        z-index: 3;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
    }

    .login_out_dialog{
        position: absolute;
        width: 6rem;
        height: 3rem;
        background: white;
        box-shadow:0rem 0rem 0.08rem rgba(0,0,0,0.5);
        border-radius: 0.1rem;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0.8rem;
        box-sizing: border-box;
    }

    .login_out_tip{
        font-size: 0.32rem;
        text-align: center;
    }

    .other_info{
        position: absolute;
        top: 7.1rem;
        left: 0;
        right: 0;
        margin: auto;
    }

    .btn_box{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 0.88rem;
        width: 100%;
        border-top: 1px solid rgba(234,239,251,1);
    }

    .btn{
        width: 50%;
        height: 0.88rem;
        text-align: center;
        line-height: 0.88rem;
        
    }

    .cancel{
        color: rgba(20, 83, 254, 1);
        border-left:1px solid rgba(234,239,251,1);
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }


</style>