<template>
  <div class="page">
    <div class="input">
      <img
        v-if="showSearch"
        class="back"
        src="@/assets/images/left@2x.png"
        @click="showSearch = false"
        alt=""
      />
      <div class="input_wrapper">
        <div class="input_inner">
          <img class="fdj" src="@/assets/images/fangdajing.png" alt="" />
          <form
            action="#"
            class="input-content"
            id="search_vesselName_form"
            onsubmit="return false"
          >
            <input
              ref="input1"
              style="width: 100%"
              autocomplete="off"
              type="search"
              placeholder="船名"
              v-model="vesselName"
              @input="vesselNameChange"
              @focus="
                searching = true;
                showSearch = true;
              "
              @blur="searching = false"
            />
          </form>
        </div>
        <div class="input_inner">
          <form
            action="#"
            class="input-content"
            id="search_voyage_form"
            onsubmit="return false"
          >
            <input
              ref="input2"
              style="width: 100%"
              type="search"
              v-model="voyage"
              placeholder="航次"
              @input="voyageChange"
              @focus="
                searching = true;
                showSearch = true;
              "
              @blur="searching = false"
            />
          </form>
        </div>
        <img
          v-if="vesselName || voyage"
          src="@/assets/images/clear.png"
          class="clear-btn"
          @click="clearInfo"
          alt=""
        />
      </div>
      <div class="search-btn" @click="add">跟踪</div>
    </div>
    <div v-if="!isLogin" class="login-tip">
      <div
        style="display: flex; align-items: center; justify-content: flex-start"
      >
        <img class="login-tip-image" src="@/assets/images/warning.png" alt="" />
        <span class="login-tip-content"
          >游客用户只保留15条记录，登录将不做限制</span
        >
      </div>
      <span class="login-tip-btn" @click="toLogin">登录</span>
    </div>
    <div v-show="!showSearch" class="save-list">
      <mescroll-vue ref="mescroll" :up="mescroll.mescrollUp" id="mescrollShip">
        <div
          v-show="Array.isArray(dataHistory) && dataHistory.length > 0"
          class="list"
        >
          <template v-for="(item, index) in dataHistory">
            <div class="list-item" :key="index">
              <div class="list-item-top">
                <span class="list-item-top-text">
                  {{ item.vesselName
                  }}{{
                    Boolean(item.vesselName) && Boolean(item.voyage) ? " / " : ""
                  }}{{ item.voyage }}
                </span>
                <span class="del-btn" @click="deleteHistory(index)">删除</span>
              </div>
              <div v-if="item.ctnStartTime && item.ctnEndTime">
                <div class="list-item-content">
                  <span class="list-item-label">开港时间：</span>
                  <span class="list-item-data">{{
                    item.ctnStartTime | translateTime
                  }}</span>
                </div>
                <div class="list-item-content">
                  <span class="list-item-label">截港时间：</span>
                  <span class="list-item-data">{{
                    item.ctnEndTime | translateTime
                  }}</span>
                </div>
                <div class="list-item-content">
                  <span class="list-item-label">中文船名：</span>
                  <span class="list-item-data">{{ item.cnVesselName }}</span>
                </div>
                <!-- <div class="list-item-content">
                  <span class="list-item-label">UN代码：</span>
                  <span class="list-item-data">{{ item.vesselCode }}</span>
                </div> -->
                <div class="list-item-content">
                  <span class="list-item-label">停靠码头：</span>
                  <span class="list-item-data">{{ item.matou }}</span>
                </div>
              </div>
              <div class="list-item-no-data" v-else>
                暂无开截港信息，请耐心等待港口系统更新，如长时间未更新请检查您的船名、航次是否正确
              </div>
            </div>
          </template>
        </div>
        <div
          v-show="!Array.isArray(dataHistory) || !dataHistory.length > 0"
          class="nodata"
        >
          <img
            class="nodata-image"
            src="@/assets/images/schedule_nodata.png"
            alt=""
          />
          <span class="nodata-tip">暂无跟踪数据，请先添加</span>
        </div>
      </mescroll-vue>
    </div>
    <div v-show="showSearch" ref="searchResultRef" class="search-result">
      <div v-if="searchList.length !== 0" class="search-list">
        <template v-for="(item, index) in searchList">
          <div
            class="search-item"
            :key="index"
            @click="chose({ vesselName: item.vesselName, voyage: item.voyage })"
          >
            <span class="search-item-vesselName">{{ item.vesselName }}</span>
            <span class="search-item-voyage">{{ item.voyage }}</span>
          </div>
        </template>
      </div>
      <div
        v-if="searchList.length === 0 && (vesselName.trim() || voyage.trim())"
        class="no-data"
      >
        港口数据还未生成，或者你输入的信息有误！
      </div>
    </div>
  </div>
</template>
<script>
import config from "../../common/config";
import moment from "moment";
import MescrollVue from "mescroll.js/mescroll.vue";

export default {
  name: "shippingSchedule",
  components: {
    MescrollVue,
  },
  data() {
    return {
      isLogin: localStorage.getItem("sessionToken") ? true : false,
      vesselName: "",
      voyage: "",
      dataHistory: [],
      searchList: [],
      searching: false,
      showSearch: false,
      mescroll: {
        mescrollUp: {
          callback: this.up,
          page: {
            num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
            size: 15, //每页数据条数,默认10
          },
          htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
          noMoreSize: 9999999,
          toTop: {
            src: "",
            offset: 1000, //列表滚动1000px才显示回到顶部按钮
          },
        },
      },
    };
  },
  filters: {
    translateTime(value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm:00") : "";
    },
  },
  watch: {
    dataHistory(value) {
      let toSave = Array.isArray(value) ? value : [];
      localStorage.setItem("dHL", JSON.stringify(toSave.slice(0, 15)));
    },
  },
  methods: {
    vesselNameChange() {
      this.vesselName = this.vesselName.replace(/[^a-zA-Z0-9 ]/g, "");
      this.getSearchList();
    },
    voyageChange() {
      this.voyage = this.voyage.replace(/[^a-zA-Z0-9 ]/g, "");
      this.getSearchList();
    },
    async getSearchList() {
      if (!this.vesselName?.trim() && !this.voyage?.trim()) return false;
      const res = await this.service.get(
        config.url + "open/vessel/search_only_name_no",
        {
          params: {
            vesselName: this.vesselName.trim(),
            voyage: this.voyage.trim(),
          },
        }
      );
      if (res.data?.code === 0) {
        this.$refs.searchResultRef.scrollTop = 0;
        this.searchList = res.data.data || [];
      }
    },
    historyHandling(newData) {
      let index = this.dataHistory.findIndex((item) => {
        return (
          item.vesselName === newData.vesselName &&
          item.voyage === newData.voyage
        );
      });
      if (index === -1) {
        this.dataHistory.unshift(newData);
        if (this.dataHistory.length > 15) {
          this.dataHistory.pop();
        }
      } else {
        if (this.dataHistory.length === 1) return false;
        this.dataHistory.splice(index, 1);
        this.dataHistory.unshift(newData);
      }
    },
    async addRequest(newData) {
      if (this.isLogin) {
        const res = await this.service.post(
          config.url + "general/vessel_track/add_vessel",
          [
            {
              vesselName: newData.vesselName,
              voyage: newData.voyage,
            },
          ]
        );
        this.showSearch = false;
        this.$nextTick(() => {
          this.$refs.mescroll.mescroll.resetUpScroll(false);
        });
      } else {
        const res = await this.service.post(
          config.url + "open/vessel/tail_after",
          [
            {
              vesselName: newData.vesselName,
              voyage: newData.voyage,
            },
          ]
        );
        if (res.data?.code !== 0) res.data.data = [newData];
        //保存的数据处理：添加的数据在最前，除重保留15条
        if (Array.isArray(res.data.data) && res.data.data.length === 0) {
          this.historyHandling(newData);
        } else {
          this.historyHandling(res.data.data[0]);
        }
        this.showSearch = false;
        this.$nextTick(() => {
          this.$refs.mescroll.mescroll.scrollTo(0, 0);
        });
      }
    },
    async add() {
      if (!this.vesselName?.trim() || !this.voyage?.trim()) return false;
      const newData = {
        vesselName: this.vesselName.trim(),
        voyage: this.voyage.trim(),
      };
      this.addRequest(newData);
    },
    chose(item) {
      const newData = {
        vesselName: item.vesselName,
        voyage: item.voyage,
      };
      this.addRequest(newData);
    },
    deleteHistory(index) {
      if (this.isLogin) {
        this.service.post(config.url + "general/vessel_track/delete_vessel", {
          ids: [this.dataHistory[index].id],
        });
      }
      this.dataHistory.splice(index, 1);
    },
    clearInfo() {
      this.vesselName = "";
      this.voyage = "";
      this.searchList = [];
    },
    async up(page, mescroll) {
      /**
       * 非登录情况下，直接拿本地数据去请求最新数据
       * 登录情况下，本地有数据服务器没数据则同步到服务器
       */
      if (!this.isLogin) {
        const list = JSON.parse(localStorage.getItem("dHL"));
        if (Array.isArray(list) && list.length > 0) {
          this.service
            .post(
              config.url + "open/vessel/tail_after",
              list.map((item) => ({
                vesselName: item.vesselName,
                voyage: item.voyage,
              }))
            )
            .then((res) => {
              if (res.data?.code === 0) {
                this.dataHistory = res.data.data;
              }
              this.$nextTick(() => {
                mescroll.endSuccess(res.data.data.length, false);
              });
            })
            .catch((error) => {
              mescroll.endErr();
            });
        } else {
          mescroll.endSuccess(0);
        }
      } else {
        var num = page.num - 1;
        this.service
          .post(config.url + "general/vessel_track/vessel_list", {
            // state: this.orderStatus
            pageNumber: num,
            pageOrder: [],
            pageSize: page.size,
          })
          .then((response) => {
            // 请求的列表数据
            let total = response.data.data.total;
            let arr = response.data.data.content;
            let localArr = JSON.parse(localStorage.getItem("dHL"));
            if (arr.length === 0 && localArr.length > 0) {
              this.service
                .post(
                  config.url + "general/vessel_track/add_vessel",
                  localArr.map((item) => ({
                    vesselName: item.vesselName,
                    voyage: item.voyage,
                  }))
                )
                .then((res) => {
                  if (res.data?.code === 0) {
                    this.$refs.mescroll.mescroll.resetUpScroll(false);
                  }
                })
                .catch((err) => {
                  console.log(err, "err");
                });
            } else {
              // 如果是第一页需手动置空列表
              if (page.num === 1) {
                this.dataHistory = [];
              }
              // 把请求到的数据添加到列表
              this.dataHistory = this.dataHistory.concat(arr);
              // 数据渲染成功后,隐藏下拉刷新的状态
              this.$nextTick(() => {
                mescroll.endBySize(arr.length, total);
              });
            }
          })
          .catch((e) => {
            console.log(e);
            // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
            mescroll.endErr();
          });
      }
    },
    toLogin() {
      this.$router.replace({
        path: `/login`,
        query: {
          redirect_pro_url: window.location.href,
        },
      });
    },
  },
  created() {
    // window.addEventListener("pageshow", this.pageshowFn);
  },
  mounted() {
    document.querySelector("body").addEventListener("touchstart", (e) => {
      if (e.target.nodeName !== "INPUT") {
        this.$refs.input1?.blur();
        this.$refs.input2?.blur();
      }
    });
  },
  beforeDestroy() {
    // window.removeEventListener("pageshow", this.pageshowFn);
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  color: #233953;
  background: #f6f6f6;
  overflow: hidden;
}
.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.24rem;
  .input_wrapper {
    position: relative;
    margin-right: 8px;
    width: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.15rem 0.15rem;
    border: 1px solid #9fa6bc;
    background-color: #fff;
    .input_inner {
      flex: 1;
      display: flex;
      align-items: center;
      width: 50%;
      &:first-child {
        border-right: 1px solid #979797;
      }
    }
    .fdj {
      height: 0.4rem;
    }
    .input-tip {
      margin-right: 0.12rem;
      margin-left: 0.12rem;
      flex: 1;
      width: 0;
      color: #9fa6bc;
      font-size: 0.28rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }
    .input-content {
      margin-right: 0.12rem;
      margin-left: 0.12rem;
      flex: 1;
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }
    .clear-btn {
      position: absolute;
      right: 0.16rem;
      width: 0.4rem;
      height: 0.4rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.36rem;
    height: 0.74rem;
    background: #0066ff;
    border-radius: 0.04rem;
    color: #fff;
    font-size: 0.32rem;
  }
}
.save-list,
.search-result {
  height: 0;
  flex: 1;
  overflow-y: auto;
}
.search-list {
  font-size: 0.28rem;
  background-color: #fff;
  .search-item {
    padding: 0.16rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f2f2f2;
    .search-item-vesselName {
      min-width: 50%;
      max-width: 70%;
      margin-right: 0.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }
    .search-item-voyage {
      flex: 1;
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }
  }
}
.save-list {
  width: 100%;
  height: 0;
  flex: 1;
  overflow: scroll;
  .list-item {
    margin-bottom: 0.24rem;
    padding: 0.24rem 0.36rem;
    font-size: 0.28rem;
    color: #152234;
    background-color: #fff;
    .list-item-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.23rem;
      padding-bottom: 0.24rem;
      border-bottom: 1px solid #dde2ee;
      .list-item-top-text {
        word-break: break-all;
        flex: 1;
        margin-right: 0.2rem;
      }
      .del-btn {
        color: #666e8b;
      }
    }
    .list-item-content {
      margin-bottom: 0.16rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      color: #0b263c;
      &:last-child {
        margin-bottom: 0;
      }
      .list-item-label {
        min-width: 1.4rem;
        color: #606266;
        &.gray-color {
          color: #0066ff;
        }
      }
      .list-item-data {
        width: 0;
        flex: 1;
      }
    }
    .list-item-no-data {
      font-size: 0.28rem;
      color: #7f8388;
      line-height: 0.4rem;
    }
  }
}
.nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4.39rem;
  text-align: center;
  .nodata-image {
    margin-bottom: 0.24rem;
    width: 3.76rem;
    height: 2.4rem;
  }
  .nodata-tip {
    font-size: 0.32rem;
    color: #7d869f;
  }
}
.no-data {
  padding: 0.16rem 0.4rem;
  background-color: #fff;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; /*去掉默认的×*/
}
/*去掉默认的搜索小图标*/
input[type="search"]::-webkit-search-decoration {
  display: none;
}
/*去掉默认的x*/
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
/*去掉ios下的圆角*/
input[type="search"] {
  -webkit-appearance: none;
  background-color: #fff;
}
input::placeholder {
  color: #9fa6bc;
  font-size: 0.28rem;
  font-family: Arial, "Microsoft Yahei", "Helvetica Neue", Helvetica, sans-serif;
}
.back {
  margin-right: 0.28rem;
  width: 0.24rem;
  height: 0.4rem;
}
.login-tip {
  margin-bottom: 0.24rem;
  padding: 0.16rem 0.36rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  .login-tip-image {
    margin-right: 0.1rem;
    width: 0.28rem;
    height: 0.28rem;
  }
  .login-tip-content {
    font-size: 0.28rem;
    line-height: 0.3rem;
  }
  .login-tip-btn {
    font-size: 0.28rem;
    line-height: 0.3rem;
    color: #0066ff;
  }
}
</style>