<template>
  <div class="page">
    <template v-if="!expire">
      <div class="content">
        <div class="img-outer">
          <template v-for="(item, index) in img_group">
            <img
              class="box-img"
              :key="index"
              :src="item"
              :preview="token"
              alt=""
            />
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content">
        <div style="margin-top: 3.92rem">
          <div style="text-align: center;margin-bottom:0.24rem">
            <img class="expire-img" src="@/assets/images/expire.png" alt="" />
          </div>
          <div class="tip">很抱歉，该链接已经失效！</div>
        </div>
      </div>
    </template>
    <div class="tip">本页面由箱动力提供</div>
    <transition name="fade">
      <gralley
        :images="img_group"
        v-if="previewImg"
        :ChangePreview="changePreview"
        :slide_index="slide_index"
      ></gralley>
    </transition>
  </div>
</template>

<script>
import Bus from "../../until/bus";
import config from "@/common/config";
export default {
  name: "BoxImages",
  data() {
    return {
      token: this.$route.params.token,
      expire: this.$route.params.token ? false : true,
      previewImg: false,
      img_group: [],
      slide_index: 0,
    };
  },
  created() {
    if (this.expire) return false;
    this.watchPreview();
    this.getImgs();
  },
  methods: {
    getImgs() {
      this.service
        .get(config.url + "open/order/box_image/" + this.token)
        .then((res) => {
          if (res?.data?.code === 0) {
            this.img_group = res.data.data;
            this.$nextTick(() => {
              this.$previewRefresh();
            });
          } else {
            this.expire = true;
          }
        })
        .catch((e) => {
          this.expire = true;
        });
    },
    watchPreview() {
      Bus.$on("preview_imgs", (r, i) => {
        this.img_group = r;
        this.slide_index = i;
        this.changePreview(true);
      });
      Bus.$on("hidegralley", () => {
        this.previewImg = false;
      });
    },
    preview(index) {
      this.slide_index = index;
      Bus.$emit("preview_imgs", this.img_group, index);
    },
    changePreview(bool) {
      this.previewImg = bool;
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  height: 100vh;
  box-sizing: border-box;
}
.content {
  flex: 1;
  padding: 0.12rem 0.24rem;
  width: 100%;
  height: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  .img-outer {
    display: flex;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    flex-flow: row wrap;
    box-sizing: border-box;
    margin: 0 -0.07rem;
    .box-img {
      width: 3.43rem;
      margin: 0 0.07rem 0.14rem;
      height: 4.6rem;
      object-fit: cover;
    }
  }
}
.tip {
  padding: 0.19rem 0;
  width: 100%;
  text-align: center;
  color: #7d869f;
}
.expire-img {
  width: 2.4rem;
  height: 2.21rem;
}
</style>