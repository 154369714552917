<!--
 * @Author: your name
 * @Date: 2020-08-16 12:09:29
 * @LastEditTime: 2021-07-07 09:38:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /chp_h5/src/components/tabBar.vue
-->
<template>
      <div class="tab_bar">
        <div :class="[this.tabBar_status==1?'tab_item tab_choosen':'tab_item']" @click="changeTabState(1)">  
            <p class="tab_item_icon iconfont">&#xe68c;</p>
            <p class="tab_item_text">待派单</p>
        </div>
        <div :class="[this.tabBar_status==2?'tab_item tab_choosen':'tab_item']" @click="changeTabState(2)">  
            <p class="tab_item_icon iconfont">&#xe68c;</p>
            <p class="tab_item_text">进行中</p>
        </div>
        <div :class="[this.tabBar_status==5?'tab_item tab_choosen':'tab_item']" @click="changeTabState(5)">  
            <p class="tab_item_icon iconfont">&#xe616;</p>
            <p class="tab_item_text">已完成</p>
        </div>
        <div :class="[this.tabBar_status==6?'tab_item tab_choosen':'tab_item']" @click="changeTabState(6)">  
            <p class="tab_item_icon iconfont">&#xe6af;</p>
            <p class="tab_item_text">我的账号</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tabBar',
    props:["tabBar_status"],
    mounted(){
        //console.log("当前tab",this.tabBar_status)
    },
    methods:{
        changeTabState:function(index){
            this.$emit("changeTabStatus",index)
        }
    }

}   
</script>

<style scoped>
    .tab_bar{
        width: 100%;
        height:0.98rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0;
        right: 0;
        border-top: 1px solid rgba(205,205,205,1);
        background-color: white;
        z-index: 2;
    }

    .tab_item{
        flex: 1;
        color: rgba(96, 98, 102, 1);
    }

    .tab_item p{
        text-align: center;

    }

    .tab_item_icon{
        margin-bottom: 0.1rem;
        font-size: 0.4rem;
    }

    .tab_item_text{
        font-size: 0.28rem;
    }

    .tab_choosen{
        color:rgba(20, 83, 254, 1)
    }
</style>