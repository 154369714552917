<template>
  <div class="form">
    <div class="title_area">
      <span class="title">我是货代</span>
      <span class="chose_btn">重新选择身份</span>
    </div>
    <div style="margin-bottom: 0.6rem">
      <div class="section_title">账号信息</div>
      <div class="form_item">
        <span class="form_item_label">手机号</span>
        <input class="form_item_content" placeholder="请填写手机号" />
      </div>
      <div class="form_item">
        <span class="form_item_label">验证码</span>
        <input class="form_item_content" placeholder="请填写验证码" />
        <div class="code_btn">
            <span v-if="!timer" @click="getCode">获取验证码</span>
            <span v-else>{{count}}s</span>
        </div>
      </div>
      <div class="form_item">
        <span class="form_item_label">设置密码</span>
        <input
          class="form_item_content"
          placeholder="6-32位密码，区分大小写"
          :type="[input_type ? 'password' : 'text']"
        />
        <div class="changeType iconfont" @click="input_type=!input_type" style="margin-left:0.2rem">
          {{ input_type ? "&#xe622;" : "&#xe6c4;" }}
        </div>
      </div>
    </div>
    <div style="margin-bottom: 0.6rem">
      <div class="section_title">公司信息</div>
      <div class="form_item">
        <span class="form_item_label">公司名称</span>
        <input class="form_item_content" placeholder="请填写公司名称" />
      </div>
      <div class="form_item">
        <span class="form_item_label">账号负责人</span>
        <input class="form_item_content" placeholder="请填写账号负责人" />
      </div>
      <div class="form_item">
        <span class="form_item_label">手机号码</span>
        <input class="form_item_content" placeholder="请填写手机号码" />
      </div>
      <div class="form_item">
        <span class="form_item_label">QQ(选填)</span>
        <input class="form_item_content" placeholder="请填写负责人QQ号码" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompanyRegister",
  data(){
      let timeLimit = 60
      return{
          input_type: false,
          timer: null,
          timeLimit,
          count: timeLimit
      }
  },
  methods:{
      getCode(){
          this.count = this.timeLimit
          this.timer = setInterval(()=>{
              this.count = this.count -1
              if(this.count<1){
                  clearInterval(this.timer)
                  this.timer = 0
              }
          },1000)
      }
  },
	beforeDestroy(){
    if(this.timer) clearInterval(this.timer)
	}
};
</script>
<style lang="scss" scoped>
.form {
  padding: 0.88rem 0.4rem 0.4rem;
}
.title_area {
  margin-bottom: 0.62rem;
  display: flex;
  align-items: flex-end;
}
.title {
  margin-right: 0.24rem;
  color: #0f354f;
  font-size: 0.4rem;
}
.chose_btn {
  font-size: 0.32rem;
  color: #0066ff;
}
.section_title {
  margin-bottom: 0.24rem;
  color: #666e8b;
}
.form_item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #9FA6BC;
  margin-bottom: 0.39rem;
  padding-bottom: 0.24rem;
  font-size: 0.28rem;
  .form_item_label {
    min-width: 1.4rem;
    height: 0.4rem;
    line-height: 0.4rem;
    margin-right: 0.12rem;
    color: #1c2e45;
  }

  .form_item_content {
    width: 0;
    flex: 1;
    height: 0.4rem;
    font-size: 0.28rem;
  }
  .code_btn{
      width: 2.09rem;
      text-align: center;
      border-left: 1px solid #9FA6BC;
      color:#0066FF;
  }
}
</style>