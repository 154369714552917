<template>
  <div v-if="company_info">
    <navgationBar
      :back="back"
      :search="search"
      :title="title"
      @changeSearchbar="changeSearchBar"
      v-show="tabBar_status != 6"
    ></navgationBar>
    <!-- 待派单列表 -->
    <div v-show="tabBar_status == 1">
      <mescroll-vue ref="mescroll3" :up="mescroll3.mescrollUp" id="mescroll3">
        <div class="content">
          <orderBox
            v-for="(item, index) in dataList3"
            :key="index"
            :data="item"
            @show_alert="show_copy_alert"
          ></orderBox>
        </div>
      </mescroll-vue>
    </div>

    <!-- 进行中列表 -->
    <div v-show="tabBar_status == 2">
      <mescroll-vue ref="mescroll1" :up="mescroll1.mescrollUp" id="mescroll1">
        <div class="content">
          <orderBox
            v-for="(item, index) in dataList1"
            :key="index"
            :data="item"
            @show_alert="show_copy_alert"
          ></orderBox>
        </div>
      </mescroll-vue>
    </div>

    <!-- 已完成列表 -->
    <div v-show="tabBar_status == 5">
      <mescroll-vue ref="mescroll2" :up="mescroll2.mescrollUp" id="mescroll2">
        <div class="content">
          <orderBox
            v-for="(item, index) in dataList2"
            :key="index"
            :data="item"
            @show_alert="show_copy_alert"
          ></orderBox>
        </div>
      </mescroll-vue>
    </div>

    <!-- 账号信息 -->
    <div v-show="tabBar_status == 6">
      <account :companyInfo="company_info" :tabBarStatus="tabBar_status"></account>
    </div>

    <tabBar
      :tabBar_status="tabBar_status"
      @changeTabStatus="changeTab"
    ></tabBar>
    <searchBar
      v-show="search_bar_status"
      @changeSearchbar="changeSearchBar"
      :search_bar_status="search_bar_status"
      :tab_status="tabBar_status"
    ></searchBar>
    <transition name="fade">
      <gralley
        :images="img_group"
        v-if="previewImg"
        :ChangePreview="changePreview"
        :slide_index="slide_index"
      ></gralley>
    </transition>

    <transition name="fade">
      <div class="copy_alert" v-if="copy_alert_show">
        {{ copy_alert_title }}
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import tabBar from "@/components/tabBar.vue";
import navgationBar from "@/components/navgationBar.vue";
import orderBox from "@/components/orderBox.vue";
import searchBar from "@/components/searchBar.vue";
import account from "@/components/account.vue";
import Bus from "@/until/bus";
import MescrollVue from "mescroll.js/mescroll.vue";
import config from "@/common/config";
import no_order from "@/assets/no_order.png";
import moment from "moment";

export default {
  name: "Home",
  components: {
    tabBar,
    navgationBar,
    orderBox,
    searchBar,
    account,
    MescrollVue,
  },
  data() {
    return {
      slide_index: 0,
      //nav配置
      back: false,
      search: true,
      title: "工作台",
      copy_alert_title: "",
      copy_alert_show: false,
      company_info:"",
      //当前tab选中的索引，0工作台，1已完成，2我的账号
      tabBar_status: 1,

      //是否显示搜索框
      search_bar_status: false,

      img_group: [],
      previewImg: false,
      mescroll1: {
        mescrollUp: {
          orderStatus: 2,
          callback: this.up,
          page: {
            num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
            size: 10, //每页数据条数,默认10
          },
          htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
          noMoreSize: 5,
          toTop: {
            src: "",
            offset: 1000, //列表滚动1000px才显示回到顶部按钮
          },
          empty: {
            //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
            warpId: "mescroll1", //父布局的id (1.3.5版本支持传入dom元素)
            icon: no_order, //图标,默认null,支持网络图
            tip: "暂无订单数据", //提示
          },
        },
      },
      mescroll2: {
        mescrollUp: {
          orderStatus: 5,
          callback: this.up,
          page: {
            num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
            size: 10, //每页数据条数,默认10
          },
          htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
          noMoreSize: 10,
          toTop: {
            src: "",
            offset: 1000, //列表滚动1000px才显示回到顶部按钮
          },
          empty: {
            //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
            warpId: "mescroll2", //父布局的id (1.3.5版本支持传入dom元素)
            icon: no_order, //图标,默认null,支持网络图
            tip: "暂无订单数据", //提示
          },
        },
      },
      mescroll3: {
        mescrollUp: {
          orderStatus: 1,
          callback: this.up,
          page: {
            num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
            size: 10, //每页数据条数,默认10
          },
          htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
          noMoreSize: 5,
          toTop: {
            src: "",
            offset: 1000, //列表滚动1000px才显示回到顶部按钮
          },
          empty: {
            //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
            warpId: "mescroll3", //父布局的id (1.3.5版本支持传入dom元素)
            icon: no_order, //图标,默认null,支持网络图
            tip: "暂无订单数据", //提示
          },
        },
      },
      dataList1: [],
      dataList2: [],
      dataList3: [],
    };
  },
  created() {
    this.service.get(config.url + "general/user/my_info").then((res) => {
      if (res.data.data.companyType !== 1) {
        alert("暂不支持车队账号登录");
        this.login_out();
        return false;
      }
      this.company_info = {
        companyName: res.data.data.companyName,
        name: res.data.data.name,
      };
      var that = this;
      Bus.$on("preview_imgs", (r, i) => {
        that.img_group = r;
        that.slide_index = i;
        that.changePreview(true);
      });
      Bus.$on("hidegralley", () => {
        that.previewImg = false;
      });
    });
  },
  beforeRouteEnter(to, from, next) {
    // var token = localStorage.getItem('token')
    // if (!token && typeof(token) != "undefined" && token != 0) {
    //   next(vm=>{
    //     vm.$router.push({ path: '/login'})
    //   })
    // }else{
    next((vm) => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$nextTick(()=>{
        vm.$refs.mescroll1 && vm.$refs.mescroll1.beforeRouteEnter();
        vm.$refs.mescroll2 && vm.$refs.mescroll2.beforeRouteEnter();
        vm.$refs.mescroll3 && vm.$refs.mescroll3.beforeRouteEnter();
      })
    });
    // }
  },

  beforeRouteLeave(to, from, next) {
    // 如果没有配置顶部按钮或isBounce,则beforeRouteLeave不用写
    // 记录列表滚动的位置,隐藏顶部按钮和isBounce的配置
    this.$refs.mescroll1 && this.$refs.mescroll1.beforeRouteLeave();
    this.$refs.mescroll2 && this.$refs.mescroll2.beforeRouteLeave();
    this.$refs.mescroll3 && this.$refs.mescroll3.beforeRouteLeave();
    next();
  },

  methods: {
    show_copy_alert(title) {
      var that = this;
      this.copy_alert_title = title;
      this.copy_alert_show = true;
      setTimeout(function () {
        that.copy_alert_show = false;
      }, 500);
    },

    changeTab: function (index) {
      this.tabBar_status = index;
    },
    changeSearchBar: function (bool) {
      this.search_bar_status = bool;
    },
    changePreview(bool) {
      this.previewImg = bool;
    },
    up(page, mescroll) {
      // 联网请求
      var num = page.num - 1;
      let orderState = mescroll.options.up.orderStatus;
      this.service
        .post(config.url + "cus/order/page", {
          // state: this.orderStatus
          containerTypes: [20, 40, 41, 46],
          orderState: orderState,
          pageNumber: num,
          pageOrder: [],
          pageSize: page.size,
          orPackUpAtIsNull: true,
          packUpAtEnd: moment().add(1,'year').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          packUpAtStart: moment().subtract(1,'year').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          includeBoxImageUrl: true
        })
        .then((response) => {
          // 请求的列表数据
          let arr = response.data.data.content;
          if (orderState == 2) {
            // 如果是第一页需手动置空列表
            if (page.num === 1) {
              this.dataList1 = [];
            }

            // 把请求到的数据添加到列表
            this.dataList1 = this.dataList1.concat(arr);
            console.log(2, this.dataList1);
          } else if (orderState == 5) {
            // 如果是第一页需手动置空列表
            if (page.num === 1) this.dataList2 = [];

            // 把请求到的数据添加到列表
            this.dataList2 = this.dataList2.concat(arr);
            console.log(5, this.dataList2);
          } else if (orderState == 1) {
            if (page.num === 1) this.dataList3 = [];
            this.dataList3 = this.dataList3.concat(arr);
            console.log(1, this.dataList3);
          }

          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length);
          });
        })
        .catch((e) => {
          console.log(e);
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr();
        });
    },
    login_out() {
      localStorage.removeItem("sessionToken");
      this.$router.push({ name: "Login" });
    }
    // mescrollInit (mescroll) {
    //   this.mescroll = mescroll
    // },
  },
};
</script>

<style scoped>
.mescroll {
  position: fixed;
  top: 0.88rem;
  bottom: 0.88rem;
  height: auto;
  z-index: 0;
  overflow: auto;
}

.content {
  padding: 0.2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.copy_alert {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 50%;
  height: 44px;
  background-color: rgba(29, 29, 29, 0.9);
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 44px;
}
</style>
