<template>
  <div class="order_box">
    <p :class="[this.data.state == 5 ? 'order_no finish' : 'order_no']">
      <span>订单编号：</span>
      <span>{{ data.number }}</span>
    </p>
    <div class="order_info">
      <div class="info_row">
        <p class="title">船名/航次：</p>
        <p class="info">
          <span>{{ data.shipName }}</span
          ><span> / </span><span>{{ data.shipNo }}</span>
        </p>
      </div>
      <div class="info_row">
        <p class="title">提单号：</p>
        <p class="info">{{ data.extractNo }}</p>
      </div>
      <div class="info_row">
        <p class="title">派单车队：</p>
        <p class="info">{{ data.carTeamName }}</p>
      </div>
      <div class="info_row">
        <p class="title">箱型：</p>
        <p class="info">1 X {{ containerTypeCN }}</p>
      </div>
      <div class="info_row">
        <p class="title">特种箱型：</p>
        <p class="info">{{data.useSpecialCtn? specialCtnTypeCN:"" }}</p>
      </div>
      <div class="info_row">
        <p class="title">装箱时间：</p>
        <p class="info">{{ packAt }}</p>
      </div>
      <div class="info_row">
        <p class="title">装箱地点：</p>
        <p class="info place">{{ place }}</p>
      </div>
      <div class="info_row">
        <p class="title">箱号：</p>
        <p class="info">{{ data.containerNo }}</p>
      </div>
      <div class="info_row">
        <p class="title">封号：</p>
        <p class="info">{{ data.sealNo }}</p>
      </div>
      <div class="info_row">
        <p class="title">柜重：</p>
        <p class="info">
          <span>{{ data.containerWeight }}</span
          ><span>KGS</span>
        </p>
      </div>
      <div class="info_row">
        <p class="title">箱封照：</p>
        <div class="imgbox">
          <img
            v-for="(item, index) in image_group"
            :preview="data.id"
            class="imginfo"
            :src="item"
            :key="index"
          />
        </div>
      </div>
    </div>
    <div class="btn_box">
      <div class="deatil_btn btn" @click="this.toDetail">订单详情</div>
      <div
        class="getinfo_btn btn"
        @click="beforeCopy"
      >
        报箱封号
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../until/bus";
import Clipboard from "clipboard";
import config from "@/common/config";
import moment from "moment";
export default {
  name: "orderBox",
  props: ["data"],
  data() {
    return {
      image_group: [],
      base_copy_data: "",
      copy_data: "",
      slide_index: 0,
      packAt: "",
      place: "",
      containerTypeCN: "",
      specialCtnTypeCN:""
    };
  },
  watch: {
    data: {
      handler(val, oldVal) {
        this.initData(val);
      },
      deep: true,
    },
  },
  methods: {
    initData(value) {
      let packUpAt = value.packUpAt ? moment(value.packUpAt).format("YYYY.MM.DD HH点") : "";
      let shipName = value.shipName == null ? "" : value.shipName;
      let shipNo = value.shipNo == null ? "" : value.shipNo;
      let extractNo = value.extractNo == null ? "" : value.extractNo;
      let number = value.containerNo == null ? "" : value.containerNo;
      let closeNo = value.sealNo == null ? "" : value.sealNo;
      let weight =
        value.containerWeight == null ? "" : value.containerWeight + "KGS";
      let serialNo = value.serialNo == null ? "" : value.serialNo;
      let packUpLocation =
        value.packUpLocationVO == null ? [] : value.packUpLocationVO;
      let extractGround =
        value.extractGround == null ? "" : value.extractGround;
      let returnGround = value.returnGround == null ? "" : value.returnGround;
      let packAt = value.packUpAt
        ? moment(value.packUpAt).format("MM-DD/HH点")
        : "";
      this.packAt = packAt;

      switch (value.containerType) {
        case 20:
          this.containerTypeCN = "20GP";
          break;
        case 40:
          this.containerTypeCN = "40GP";
          break;
        case 41:
          this.containerTypeCN = "40HQ";
          break;
        case 46:
          this.containerTypeCN = "45HQ";
          break;
      }
      switch (value.specialCtnType) {
        case 21:
          this.specialCtnTypeCN ="框架箱 FR"
          break;
        case 22:
          this.specialCtnTypeCN ="冷藏箱 RF"
          break;
        case 23:
          this.specialCtnTypeCN ="冷藏高箱 RH"
          break;
        case 24:
          this.specialCtnTypeCN ="油罐箱 TK"
          break;
        case 25:
          this.specialCtnTypeCN ="开顶箱 OT"
          break;
        case 26:
          this.specialCtnTypeCN ="开顶箱 OQ"
          break;
        case 51:
          this.specialCtnTypeCN ="框架箱 FR"
          break;
        case 52:
          this.specialCtnTypeCN ="冷藏箱 RF"
          break;
        case 53:
          this.specialCtnTypeCN ="油罐箱 TK"
          break;
        case 54:
          this.specialCtnTypeCN ="开顶箱 OT"
          break;
        case 55:
          this.specialCtnTypeCN ="开顶箱 OQ"
          break;
        case 61:
          this.specialCtnTypeCN ="冷藏高箱 RH"
          break;
        case 71:
          this.specialCtnTypeCN ="冷藏箱 RF"
          break;
      }
       
      var tmp_copy_1 =
        "装箱时间：" +
        packUpAt + 
        "\n" +
        "船名/航次：" +
        shipName +
        " / " +
        shipNo +
        "\n" +
        "提单号：" +
        extractNo +
        "\n" +
        "箱号：" +
        number +
        "\n" +
        "封号：" +
        closeNo +
        "\n" +
        "柜重：" +
        weight +
        "\n" +
        "序列号：" +
        serialNo +
        "\n" +
        "箱型：" +
        "1 X " +
        this.containerTypeCN +
        "\n"+"特种箱型："+  (value.useSpecialCtn ? this.specialCtnTypeCN + "\n" : ""+ "\n");

      var tmp_copy_2 = "装箱地址：";

      var tmp_place = "";
      for (var i = 0; i < packUpLocation.length; i++) {
        var location = packUpLocation[i];
        if (i == packUpLocation.length - 1) {
          tmp_place += location.areaFormatShort;
        } else {
          tmp_place += location.areaFormatShort + " + ";
        }
        tmp_copy_2 +=
          (i === 0 ? "" : "                  ") +
          `${location.areaFormat} ${location.detail} ${location.contactName} ${location.contactPhone}\n`;
      }

      this.place = tmp_place;

      var tmp_copy_3 =
        "提箱堆场：" +
        extractGround +
        "\n" +
        "还箱堆场：" +
        returnGround +
        "\n";

      var tmp_copy_4 =
        "司机：" +
        value.tempDriverName +
        "\n" +
        "手机号码：" +
        value.tempDriverPhone +
        "\n" +
        "车牌号：" +
        value.tempCarLicence +
        "\n" +
        "白卡号：" +
        value.whiteCardNumber +
        "\n";

      this.base_copy_data = tmp_copy_1 + tmp_copy_2 + tmp_copy_3 + tmp_copy_4;

      if (value.sealAtt?.length > 0) {
        for (let i = 0; i < value.sealAtt.length; i++) {
          this.image_group = value.sealAtt.map((img) => img.url);
        }
      } else {
        this.image_group = [];
      }
      this.$previewRefresh();
    },
    preview(index) {
      this.slide_index = index;
      Bus.$emit("preview_imgs", this.image_group, index);
    },
    toDetail() {
      this.$router.push({ name: "Detail", query: { id: this.data.id } });
    },
    async beforeCopy() {
      const boxImgUrl = this.data.boxImgUrl;
      this.copy_data = this.base_copy_data;
      if (this.data.sealAtt.length > 0 && boxImgUrl) {
        this.copy_data += "箱封照：" + boxImgUrl + "\n";
      }
      this.copy();
    },
    copy() {
      let el = document.createElement("textarea");
      el.value = this.copy_data;
      el.style.opacity = "0";
      document.querySelector("body").appendChild(el);
      el.select();
      // 执行复制
      document.execCommand("Copy");
      document.querySelector("body").removeChild(el);
      this.$emit("show_alert", "复制成功");
    },
  },
  mounted() {
    this.initData(this.data);
  },
};
</script>

<style scoped>
.order_box {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 0.12rem;
  margin-bottom: 0.32rem;
  width: 100%;
}
.order_no {
  background: rgba(20, 83, 254, 1);
  border-radius: 0.12rem 0.12rem 0rem 0rem;
  box-sizing: border-box;
  padding: 0 0.4rem 0 0.4rem;
  text-align: left;
  height: 0.8rem;
}

.finish {
  background-color: #33d43e;
}

.order_no span {
  font-size: 0.28rem;
  font-weight: 800;
  line-height: 0.8rem;
  color: rgba(255, 255, 255, 1);
}

.order_info {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.4rem 0rem 0rem 0.24rem;
}

.info_row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 0.32rem;
  margin-right: 0.24rem;
}

.info_row:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.title {
  width: 1.6rem;
  color: rgba(102, 102, 102, 1);
  font-size: 0.28rem;
  text-align: right;
}

.info {
  font-size: 0.28rem;
  font-weight: 500;
  color: rgba(50, 50, 50, 1);
  width: 5.25rem;
}

.imgbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.imginfo {
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: 0.2rem;
  margin-right: 0.1rem;
}

.btn_box {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0.4rem 0.2rem;
}

.btn {
  width: 3.3rem;
  height: 0.72rem;
  text-align: center;
  line-height: 0.72rem;
}

.deatil_btn {
  background: rgba(242, 242, 242, 1);
  color: rgba(96, 98, 102, 1);
  border: 1px solid rgba(205, 205, 205, 1);
  border-radius: 4px;
  margin-right: 0.1rem;
  box-sizing: border-box;
}

.getinfo_btn {
  background: rgba(20, 83, 254, 1);
  color: white;
  border-radius: 4px;
  margin-left: 0.1rem;
}

.place {
  line-height: 0.35rem;
}
</style>