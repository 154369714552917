<template>
  <div class="container">
    <div class="login_btn_outer">
      <span class="login_btn" @click="toLogin">登录</span>
    </div>
    <div class="tip">请选择您的身份</div>
    <div class="chose_area-outer">
      <div 
        :class="['chose_area', chosen === 'company' ? 'active' : '']"
        @click="chose('company')"
      >
        <img :src="require('@/assets/images/ship.png')" alt="" />
        <span>我是货代</span>
      </div>
      <div 
        :class="['chose_area', chosen === 'trucking' ? 'active' : '']"
        @click="chose('trucking')"
      >
        <img :src="require('@/assets/images/trucking.png')" alt="" />
        <span>我是车队</span>
      </div>
    </div>
    <div 
      class="next_button" 
      @click="next"
    >
      下一步
    </div>
  </div>
</template>
<script>
export default {
  name: "RegisterChose",
  data(){
      return{
          chosen: 'company'
      }
  },
  methods: {
    toLogin() {
      this.$router.push({
        name: "Login",
        query: this.$route.query
      });
    },
    chose(name){
      if(name === 'company' && this.chosen !== 'company'){
        this.chosen = 'company'
      }else if(name === 'trucking' && this.chosen !== 'trucking'){
        this.chosen = 'trucking'
      }
    },
    next(){
      this.$router.push({
        name:  this.chosen === 'company' ? "CompanyRegister" : 'TruckingRegister',
        query: this.$route.query
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0.4rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  font-size: 0.32rem;
  background-image: url('~@/assets/register_bg1.png') ;
  background-size: 100% auto;
  background-position-y: bottom ;
  background-repeat: no-repeat;
}
.login_btn_outer {
  text-align: right;
}
.login_btn {
  color: #0066ff;
}
.tip {
  margin-top: 1.06rem;
  margin-bottom: 0.6rem;
  color: #0f354f;
  text-align: center;
  font-weight: 500;
  font-size: 0.48rem;
}
.chose_area-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.6rem;
  .chose_area {
    padding: 1.15rem 0 1rem;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 0.08rem;
    border: 0.02rem solid #dde2ee;
    &.active {
      border-color: #0066ff;
      color: #0066ff;
    }
    img {
      height: 1.05rem;
    }
    &:first-child {
      margin-right: 0.22rem;
    }
  }
}
.next_button {
  width: 3.2rem;
  height: 0.9rem;
  background-color: rgba(20, 83, 254, 1);
  text-align: center;
  color: white;
  line-height: 0.9rem;
  margin: 0.8rem auto 0;
  border-radius: 4px;
}
</style>