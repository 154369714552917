/*
 * @Author: your name
 * @Date: 2020-11-16 11:14:45
 * @LastEditTime: 2024-04-07 11:54:22
 * @LastEditors: 2239486223@qq.com kh0_8f3zbzjju@dingtalk.com
 * @Description: In User Settings Edit
 * @FilePath: /chp_h5/src/main.js
 */


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/reset.css'
import './assets/css/iconfont/iconfont.css'
import axios from 'axios'
import service from "@/common/request"
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'


Vue.use(preview)

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.service = service;

router.beforeEach((to, from, next) => {
  console.log(to, "to");
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //authorize true 可以不需要登录凭证直接访问
  if (to.meta.authorize) {
    next()
  } else {
    var token = localStorage.getItem('sessionToken')
    if (to.name != "Login" && !token) {
      // next({
      //   name: "Login",
      //   replace: true
      // })
      window.location.href = window.location.origin + '/login'
    } else {
      next()
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')